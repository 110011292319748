<template>
  <div class="wx_category">
    <el-button type="primary" plain size="small" style="margin-bottom: 10px;" @click="dialogCreateCategoryVisible = true"><i class="el-icon-plus"></i>添加分类</el-button>
    <el-table :data="tableDataCategory" style="width: 100%" border size="mini" v-loading="loading">
      <el-table-column prop="id" label="ID" width="50" fixed="left">
      </el-table-column>
      <el-table-column
        prop="name"
        label="分类名称">
      </el-table-column>
      <el-table-column prop="link2_num" label="关联商品数">
        <template slot-scope="scope">
          {{scope.row.goods_num === null?0:scope.row.goods_num}}
        </template>
      </el-table-column>
      <el-table-column prop="link1_num" label="关联分类数">
        <template slot-scope="scope">
          {{scope.row.category_num === null?0:scope.row.category_num}}
        </template>
      </el-table-column>
      <el-table-column label="展示状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.status === 1">展示中</el-tag>
          <el-tag type="danger" v-if="scope.row.status === 2">隐藏中</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="230">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleShowDrawer(scope.$index, scope.row.id)">关联管理</el-button>
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row.id, scope.row.name, scope.row.status)">编辑</el-button>
          <el-popconfirm title="确定要删除吗？" @confirm="handleDelete(scope.$index, scope.row.id)">
            <el-button slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :page-size="15" :current-page.sync="page_category" layout="prev, pager, next" :total="count_category"></el-pagination>
    <el-dialog :title="dialogCreateCategoryTitle" :visible.sync="dialogCreateCategoryVisible" width="20%" @close="handledialogCreateCategoryClose">
      <el-form :model="form">
        <el-form-item label="分类名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分类状态">
          <el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" active-text="展示" inactive-text="隐藏" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCreateCategoryVisible = false" size="small">取 消</el-button>
        <el-button v-if="form.id === undefined" type="primary" @click="handleAddCategory" size="small">新增</el-button>
        <el-button v-else type="primary" @click="handleUpdateCategory" size="small">更新</el-button>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="drawer" direction="ltr" title="关联到微信分类" size="40%" @close="handledrawerclose">
      <div style="padding: 10px 20px;">
        <el-tabs v-model="tab" @tab-click="handleTabClick">
          <el-tab-pane label="关联分类" name="category">
            <el-table :data="categoryData" style="width: 100%" size="mini" max-height="700"  v-loading="loading">
              <el-table-column prop="id" label="ID" width="80" fixed="left">
              </el-table-column>
              <el-table-column prop="name" label="分类名称">
              </el-table-column>
              <el-table-column label="分类图片">
                <template slot-scope="scope">
                  <el-image style="width: 35px; height: 35px" :src="scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" fixed="right">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.link === null" type="success" plain size="mini" @click="handleLink(scope.$index, scope.row.id, 1)">关联</el-button>
                  <el-button v-else size="mini" type="danger" plain @click="handleUnlink(scope.$index, scope.row.id, 1)">取消关联</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background :page-size="10" :current-page.sync="page_c" layout="prev, pager, next" :total="count_c" @current-change="handle_pc_change"></el-pagination>
          </el-tab-pane>
          <el-tab-pane label="关联商品" name="goods">
            <el-table :data="goodsData" style="width: 100%" size="mini" max-height="700" v-loading="loading">
              <el-table-column prop="id" label="ID" width="80" fixed="left">
              </el-table-column>
              <el-table-column prop="title" label="商品名称">
              </el-table-column>
              <el-table-column label="商品图片">
                <template slot-scope="scope">
                  <el-image style="width: 35px; height: 35px" :src="scope.row.image" fit="fill"></el-image>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100" fixed="right">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.link === null" type="success" plain size="mini" @click="handleLink(scope.$index, scope.row.id, 2)">关联</el-button>
                  <el-button v-else type="danger" plain size="mini" @click="handleUnlink(scope.$index, scope.row.id, 2)">取消关联</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background :page-size="10" :current-page.sync="page_g" layout="prev, pager, next" :total="count_g" @current-change="handle_pg_change"></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'wx_category',
  data () {
    return {
      active_category: 0,
      page_c: 1,
      page_g: 1,
      count_c: 0,
      count_g: 0,
      page_category: 1,
      count_category: 0,
      tableDataCategory: [{}],
      tab: 'goods',
      drawer: false,
      loading: false,
      categoryData: [{ id: 1, title: '分类名称' }],
      goodsData: [{ id: 1, title: '商品名称' }],
      dialogCreateCategoryVisible: false,
      dialogCreateCategoryTitle: '添加微信分类',
      form: { name: '', status: 2 }
    }
  },
  methods: {
    // 打开编辑分类dialog
    handleEdit (index, id, name, status) {
      this.form = { name: name, status: status, id: id }
      this.dialogCreateCategoryTitle = '编辑微信分类'
      this.dialogCreateCategoryVisible = true
    },
    handledialogCreateCategoryClose () {
      this.form = {}
    },
    // 更新微信分类
    handleUpdateCategory () {
      var that = this
      this.axios.put('admin/wxcategory', that.form).then(function (response) {
        if (response.data.code === 0) {
          that.$message.success(response.data.msg)
          that.dialogCreateCategoryVisible = false
          that.getCategoryData()
        }
        if (response.data.code === 1) {
          that.$message.error(response.data.msg)
        }
      })
    },
    // 删除微信分类
    handleDelete (index, id) {
      var that = this
      this.axios.delete('admin/wxcategory/' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message.success(response.data.msg)
            that.tableDataCategory.splice(index, 1)
          }
        })
    },
    // 新增微信分类
    handleAddCategory () {
      var that = this
      this.axios.post('admin/wxcategory', that.form).then(function (response) {
        if (response.data.code === 0) {
          that.$message.success(response.data.msg)
          that.form = {}
          that.dialogCreateCategoryVisible = false
          that.getCategoryData()
        }
        if (response.data.code === 1) {
          that.$message.error(response.data.msg)
        }
      })
    },
    // 点击微信分类列表关联分类按钮 展示关联信息
    handleShowDrawer (index, id) {
      this.active_category = id
      this.drawer = true
      this.tab = 'goods'
      this.getCategoryDetailData(id, 2, this.page_g)
    },
    // 关联分类ID  自身id 自身类型type(1:分类；2：商品)
    handleLink (index, id, type) {
      var that = this
      this.axios.post('admin/wxcategory/link', {
        category_id: that.active_category,
        type: type,
        id: id
      }).then(function (response) {
        if (response.data.code === 0) {
          that.$message.success(response.data.msg)
          var page = 1
          if (type === 2) {
            page = that.page_g
          } else {
            page = that.page_c
          }
          that.getCategoryDetailData(that.active_category, type, page)
        }
        if (response.data.code === 1) {
          that.$message.error(response.data.msg)
        }
      })
    },
    // 取消关联
    handleUnlink (index, id, type) {
      var that = this
      this.axios.delete('admin/wxcategory/link', {
        params: {
          category_id: that.active_category,
          type: type,
          id: id
        }
      }).then(function (response) {
        if (response.data.code === 0) {
          that.$message.success(response.data.msg)
          var page = 1
          if (type === 2) {
            page = that.page_g
          } else {
            page = that.page_c
          }
          that.getCategoryDetailData(that.active_category, type, page)
        }
        if (response.data.code === 1) {
          that.$message.error(response.data.msg)
        }
      })
    },
    // 切换tab 获取不同类型的数据
    handleTabClick (e) {
      if (e.name === 'category') {
        this.getCategoryDetailData(this.active_category, 1, this.page_c)
      }
      if (e.name === 'goods') {
        this.getCategoryDetailData(this.active_category, 2, this.page_g)
      }
    },
    getCategoryData () {
      var that = this
      that.loading = true
      this.axios.get('admin/wxcategory', {
        params: {
          page: that.page_category
        }
      })
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableDataCategory = response.data.list
            that.count_category = response.data.count
            that.page_category = response.data.page
            that.loading = false
          }
        })
    },
    // wxcategoryid:微信分类ID，type：链接类型（1：分类，2：商品）
    getCategoryDetailData (wxcategoryid, type, page) {
      var that = this
      this.loading = true
      this.axios.get('admin/wxcategory/' + wxcategoryid + '/' + type + '/' + page)
        .then(function (response) {
          if (response.data.code === 0) {
            var res = response.data
            if (type === 1) {
              that.categoryData = res.list
              that.count_c = res.count
            }
            if (type === 2) {
              that.goodsData = res.list
              that.count_g = res.count
            }
            that.loading = false
          }
        })
    },
    handledrawerclose () {
      this.getCategoryData()
    },
    handle_pc_change (e) {
      this.getCategoryDetailData(this.active_category, 1, e)
    },
    handle_pg_change (e) {
      this.getCategoryDetailData(this.active_category, 2, e)
    }
  },
  mounted () {
    this.getCategoryData()
  }
}
</script>

<style>

</style>
